<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9457 3.00148C16.9639 3.00049 16.982 3 17.0002 3C18.061 3 19.0784 3.42143 19.8286 4.17157C20.5787 4.92172 21.0002 5.93913 21.0002 7C21.0002 7.01816 20.9997 7.03631 20.9987 7.05444C20.891 9.02926 20.2002 10.9278 19.0135 12.51C17.9587 13.9164 16.5543 15.0167 14.945 15.7048C14.8211 16.6569 14.5024 17.576 14.0059 18.4034C13.3935 19.4241 12.5309 20.2718 11.4996 20.8663C11.2084 21.0342 10.8524 21.0446 10.552 20.8939C10.2515 20.7433 10.0468 20.4518 10.0072 20.118C9.82174 18.5578 9.11681 17.1053 8.00582 15.9943C6.8997 14.8882 5.45512 14.1846 3.90272 13.9955C3.82953 13.9884 3.7565 13.9731 3.68497 13.9492C3.42909 13.8646 3.2231 13.681 3.10613 13.4482C2.9896 13.2153 2.96574 12.9404 3.05094 12.6848C3.07497 12.6122 3.10695 12.5436 3.14576 12.4799C3.73967 11.4578 4.58284 10.6026 5.59674 9.99428C6.4242 9.4978 7.34323 9.17903 8.29539 9.05519C8.9835 7.44584 10.0838 6.04143 11.4902 4.98665C13.0724 3.8 14.9709 3.10916 16.9457 3.00148ZM5.76524 12.3615C7.13625 12.7943 8.39187 13.552 9.42003 14.5801C10.4482 15.6083 11.2059 16.8639 11.6386 18.2349C11.8855 17.9735 12.1044 17.6852 12.2909 17.3744C12.7283 16.6454 12.9726 15.8168 13.0007 14.967C13.0143 14.5549 13.2794 14.1935 13.6683 14.0567C15.1609 13.5316 16.4642 12.5758 17.4135 11.31C18.357 10.052 18.9087 8.54409 19 6.97471C18.9934 6.45342 18.7835 5.95489 18.4144 5.58579C18.0453 5.21668 17.5467 5.00674 17.0255 5.00016C15.4561 5.09147 13.9481 5.64317 12.6902 6.58665C11.4244 7.53597 10.4686 8.83931 9.9435 10.3319C9.80669 10.7208 9.44522 10.9858 9.03317 10.9995C8.18339 11.0275 7.35481 11.2718 6.62573 11.7093C6.31497 11.8957 6.02666 12.1146 5.76524 12.3615Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.87129 13.5086C8.14281 13.9895 7.97304 14.5995 7.4921 14.871C6.62479 15.3606 5.92348 16.0981 5.47807 16.989C5.15634 17.6324 4.97871 18.3347 4.95336 19.0473C5.66596 19.0219 6.36823 18.8443 7.01169 18.5226C7.90252 18.0772 8.64003 17.3759 9.12967 16.5086C9.40119 16.0276 10.0112 15.8579 10.4921 16.1294C10.973 16.4009 11.1428 17.0109 10.8713 17.4918C10.1858 18.706 9.15328 19.6879 7.90612 20.3114C6.65896 20.935 5.25398 21.1719 3.87129 20.9918C3.42149 20.9332 3.06746 20.5792 3.00886 20.1294C2.82872 18.7467 3.06563 17.3417 3.68921 16.0945C4.31279 14.8474 5.29463 13.8149 6.50886 13.1294C6.9898 12.8579 7.59978 13.0276 7.87129 13.5086Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13 9C13 7.89543 13.8954 7 15 7C16.1046 7 17 7.89543 17 9C17 10.1046 16.1046 11 15 11C13.8954 11 13 10.1046 13 9Z" fill="black"/>
    </svg>
</template>

<script>
export default {
    name: 'IconRocket'
}
</script>